import Vue from 'vue';

const status = {
  ru: {
    NEW: 'Новый',
    PENDING: 'В обработке',
    PAID: 'Поступила оплата',
    COMPLETED: 'Завершен',
    TIMEEND: 'Истекло время',
    AMLRISK: 'AML риск',
    CANCELED: 'Отменена',
    ERROR: 'Ошибка',
    PARTIALLYPAID: 'Оплачен частично'
  },
  en: {
    NEW: 'New',
    PENDING: 'Pending',
    PAID: 'Paid',
    COMPLETED: 'Completed',
    TIMEEND: 'Expired',
    AMLRISK: 'AML risk',
    CANCELED: 'Canceled',
    ERROR: 'Error',
    PARTIALLYPAID: 'Partially paid'
  }
};

function getLanguageFromCookie() {
  const match = document.cookie.match(new RegExp('(^| )language=([^;]+)'));
  return match ? match[2] : 'ru'; // Если cookie не найдены, по умолчанию используем 'ru'
}

// Установим локаль на основе cookie
const language = getLanguageFromCookie();
Vue.prototype.$status = status[language];

export default status;